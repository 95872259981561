import * as React from "react";
import { VerticalNavPane, VerticalNavPaneItem } from "./VerticalNavPane";
import { TabularData } from "./TabularData";
import { VideosData } from "./VideosData";
import { VideoViewer } from "./VideoViewer";
import { Utils } from "./Utils";
import './index.css';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const VideosListUrl: string = "https://millot-blobs-cdn.azureedge.net/photos/videos.tsv";

let _videos: string[][] = [];
let _verticalNavPaneItems: VerticalNavPaneItem[] = [];

function getThumbnailUrl(row: string[]): string {
    const posOfLastSlash = row[VideosData.Asset].lastIndexOf('/') + 1;
    const posOfQMark = row[VideosData.Asset].indexOf('?');
    const filename = row[VideosData.Asset].substring(posOfLastSlash, posOfQMark).toLowerCase();
    const filenameNoExt = filename.substring(0, filename.lastIndexOf("."));

    return "https://millot-blobs-cdn.azureedge.net/thumbnails/" + filenameNoExt + ".jpg";
}

function getYear(row: string[]): string {
    return row[VideosData.YearAndMonth].split("/")[0];
}

function renderSection(category: string, onClickThumbnail: (row: string[]) => void): JSX.Element {
    const rows = _videos.filter(row => row[VideosData.Category] === category);
    const years = Utils.dedupArray(rows.map(row => getYear(row))).sort();

    const videosByYear = years.map(year => rows.filter(row => getYear(row) === year));

    return <div>
        {videosByYear.map(
            rows => <div>
                <h1>{getYear(rows[0])}</h1>
                {rows.map(row => <img className="oneVideoThumbnail" src={getThumbnailUrl(row)} alt="" title={row[VideosData.Name]} onClick={() => onClickThumbnail(row)} />)}
            </div>)}
    </div>;
}

export function VideosLayout() {
    const [hidden, setHidden] = useState(true);
    const [showVerticalNavPane, setShowVerticalNavPane] = useState(false);
    const [showVideoViewer, setShowVideoViewer] = useState(false);
    const [currentSrc, setCurrentSrc] = useState<string | undefined>();
    const [currentTitle, setCurrentTitle] = useState<string | undefined>();

    const keywordString = useParams()["*"];

    useEffect(() => {
        const fetchData = async () => {
            _videos = await TabularData.fetchFromUrl(VideosListUrl, true);
            const names = Utils.dedupArray(_videos.map(row => row[VideosData.Category]));
            _verticalNavPaneItems = names.map(name => { return { indentLevel: 0, label: name, navigateTo: "/Videos/" + name } });
            setHidden(false);
        }
        fetchData();
    }, []);

    const onClickNavToggle = () => { setShowVerticalNavPane(!showVerticalNavPane); }
    const onClickThumbnail = (row: string[]) => {
        setCurrentSrc(row[VideosData.Asset]);
        setCurrentTitle(row[VideosData.Name]);
        setShowVideoViewer(true);
        setShowVerticalNavPane(false);
    }
    const onCloseVideoViewer = () => {
        setCurrentSrc(undefined);
        setShowVideoViewer(true);
        setShowVerticalNavPane(false);
    }

    if (hidden)
        return <div>Chargement...</div>;

    return <div className='videosPage'>
        <a className="navToggle" onClick={onClickNavToggle}>&#9776;</a>
        {showVerticalNavPane ?
            <VerticalNavPane paneItems={_verticalNavPaneItems} hide={() => setShowVerticalNavPane(false)} />
            : null}
        {keywordString === "" || keywordString === undefined ? null :
            <div>
                <div className="currentCategory">{keywordString}</div>
                {renderSection(keywordString, onClickThumbnail)}
            </div>}
        {showVideoViewer && currentSrc !== undefined && currentTitle !== undefined ?
            <VideoViewer title={currentTitle} src={currentSrc} onClose={onCloseVideoViewer} /> : null}
    </div>
}
