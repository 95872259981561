import { TabularData } from "./TabularData";

const birthString = "Naissance";
const deathString = "Décès";

function getYearFromDate(date: string) {
    return date.length < 4 ? "" : date.slice(date.length - 4);
}

function splitField(field: string): string[] {
    if (field.length === 0)
        return [];
    return field.split(';');
}

export class Event {
    type: string;
    date: string;
    place: string;
    note: string;
    constructor(type: string, date: string, place: string, note: string) {
        this.type = type;
        this.place = place;
        this.date = date.length >= 4 ? date : "";
        this.note = note;
    }
    public isEmpty(): boolean { return this.date.length == 0 && this.note.length == 0 && this.place.length == 0 }
    public formatShortDateEvent(): string { return `${getYearFromDate(this.date)}${this.place.length > 0 ? " à "+ this.place : ""}`; }
}

export class Individual {
    constructor(id: string,
        firstName: string,
        lastName: string,
        job: string,
        familyIdAsChild: string,
        familyIdsAsSpouse: string[],
        events: Event[]) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.job = job;
        this.familyIdAsChild = familyIdAsChild;
        this.familyIdsAsSpouse = familyIdsAsSpouse;
        this.events = events;
    }
    id: string;
    firstName: string;
    lastName: string;
    job: string;
    events: Event[];
    familyIdAsChild: string;
    familyIdsAsSpouse: string[];

    public getShortDates(): string {
        const birth = this.getBirthEvent();
        const death = this.getDeathEvent();
        return `${birth != undefined ? birth.formatShortDateEvent() : ""} - ${death != undefined ? death.formatShortDateEvent() : ""}`;
    }

    public getBirthEvent(): Event|undefined { return this.events.find(e => e.type == birthString); }
    public getDeathEvent(): Event|undefined { return this.events.find(e => e.type == deathString); }
}

export class Family {
    constructor(id: string,
        husbandId: string,
        wifeId: string,
        childrenIds: string[],
        events: Event[]) {
        this.id = id;
        this.husbandId = husbandId;
        this.wifeId = wifeId;
        this.childrenIds = childrenIds;
        this.events = events;
    }
    id: string;
    husbandId: string;
    wifeId: string;
    childrenIds: string[];
    events: Event[];
}

export class Patronym {
    name: string = "";
    individualIds: string[] = [];
}

export class GenealogieData {
    private static readonly patronymsUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_patronyms.tsv";
    private static readonly individualsUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_individuals.tsv";
    private static readonly familiesUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_families.tsv";
    public _patronyms: { [name: string]: Patronym } = {};
    public _patronymFirstLetters: { [letter: string]: string[] } = {};
    public _individuals: { [id: string]: Individual } = {};
    public _families: { [id: string]: Family } = {};

    private static _genealogieData: GenealogieData;

    static async getInstance(): Promise<GenealogieData> {
        if (GenealogieData._genealogieData === undefined) {
            GenealogieData._genealogieData = new GenealogieData();
            await GenealogieData._genealogieData._init();
        }
        return GenealogieData._genealogieData;
    }

    private readEvents(eventColumns: string[]): Event[] {
        let events: Event[] = [];
        for (let i = 0 ; i < eventColumns.length / 4; i++) {
            events.push(new Event(eventColumns[i * 4], eventColumns[i * 4 + 1], eventColumns[i * 4 + 2], eventColumns[i * 4 + 3]));
        }

        return events;
    }

    private async _init() {
        const patronymList = await TabularData.fetchFromUrl(GenealogieData.patronymsUrl, false);
        const individualList = await TabularData.fetchFromUrl(GenealogieData.individualsUrl, false);
        const familyList = await TabularData.fetchFromUrl(GenealogieData.familiesUrl, false);

        patronymList.forEach((p: string[]) => {
            const patronym = p[0];
            this._patronyms[patronym] = { name: patronym, individualIds: p[1].split(';') };

            const patronymAllCap = patronym.toLocaleUpperCase();
            const firstLetter = (
                patronymAllCap.startsWith("(DU)") ||
                patronymAllCap.startsWith("DE ") ||
                patronymAllCap.startsWith("DU ") ||
                patronymAllCap.startsWith("D'") ||
                patronymAllCap.startsWith("DES ")) ?
                "DE" : patronymAllCap[0];
            let letter = this._patronymFirstLetters[firstLetter];
            if (letter)
                letter.push(patronym);
            else
                this._patronymFirstLetters[firstLetter] = [patronym];
        });

        individualList.forEach((row: string[]) => {
            this._individuals[row[0]] = new Individual(row[0], row[1], row[2], row[3], row[4], splitField(row[5]), this.readEvents(row.slice(6)));
        });

        familyList.forEach((row: string[]) => {
            this._families[row[0]] = new Family(row[0], row[1], row[2], splitField(row[3]), this.readEvents(row.slice(4)));
        });
    }
}
