import * as React from "react";
import './index.css';
import { GenealogieData } from "./GenealogieData";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const initialParameterName = "initiale";
let _genealogieData: GenealogieData;

export function shouldBold(bold: boolean, children:JSX.Element): JSX.Element {
    return bold? <b>{children}</b> : <span>{children}</span>;
}

export function GenealogieLayout() {
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            _genealogieData = await GenealogieData.getInstance();
            setHidden(false);
        }
        fetchData();
    }, []);

    const letter = useSearchParams()[0].get(initialParameterName);

    if (hidden || _genealogieData === undefined)
        return <span>Chargement des données...</span>;

    const letters = Object.entries(_genealogieData._patronymFirstLetters).sort(
        (a, b) => { return a[0] === "DE" ? 1 : b[0] === "DE" ? -1 : a[0].localeCompare(b[0]) });
    const patronymFirstLetterButtons: JSX.Element[] = letters.map((letter) =>
        <button><Link to={`/Genealogie?${initialParameterName}=${letter[0]}`}>{letter[0]} ({letter[1].length})</Link></button>);

    const patronymButtons: JSX.Element[] = 
        letter != null ? 
            _genealogieData._patronymFirstLetters[letter].map(patronym =>
                <button>
                    <Link to={"/Genealogie/Patronyme/" + patronym}>
                        {shouldBold(
                            _genealogieData._patronyms[patronym].individualIds.length >= 10, 
                            <span>{patronym} ({_genealogieData._patronyms[patronym].individualIds.length })</span>)}
                    </Link>
                </button>) 
            : [];

    return <div className='genealogiePage'><div>{patronymFirstLetterButtons}</div><p /><div>{patronymButtons}</div></div>
}
