import * as React from "react";
import './index.css';
import { VerticalNavPane, VerticalNavPaneItem } from "./VerticalNavPane";
import { PhotoViewer, PhotoViewerBackground, PhotoViewerImageDef } from "./PhotoViewer";
import { PhotosData, Keyword } from "./PhotosData";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

let _photosData: PhotosData;
let _verticalNavPaneItems: VerticalNavPaneItem[] = [];

function getVerticalNavPaneItems(): VerticalNavPaneItem[] {
    let currentKeywords: string[] = [];

    const items: VerticalNavPaneItem[] = [];

    items.push({ indentLevel: 0, label: "Nouveau" });

    const recents = _photosData._recentKeywords.map(k => _photosData._keywords[k]);
    items.push(...recents.map(k => ({
        indentLevel: 1,
        label: k.name,
        navigateTo: "/Photos/" + k.name,
        count: k.photoIds.length
    })));

    Object.keys(_photosData._keywords).forEach((keywordId) => {
        const keyword = _photosData._keywords[keywordId];
        const splitKeywords = keyword.name.split('/');

        for (let i = 0; i < splitKeywords.length; i++) {
            if (currentKeywords.length <= i || currentKeywords[i] !== splitKeywords[i]) {
                currentKeywords = currentKeywords.slice(0, i);
                currentKeywords[i] = splitKeywords[i];

                const isHeader = i !== splitKeywords.length - 1;

                items.push({
                    indentLevel: i,
                    label: currentKeywords[i],
                    navigateTo: isHeader ? undefined : "/Photos/" + keyword.name,
                    count: isHeader ? undefined : keyword.photoIds.length
                });
            }
        }
    });

    return items;
}

function getCurrentKeyword(keywordString: string): Keyword | undefined {
    const keywordId = _photosData._nameToKeywordId[keywordString];
    if (keywordId === undefined)
        return undefined;

    return _photosData._keywords[keywordId];
}

export function PhotosLayout() {
    const [fullSizeVisible, setFullSizeVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showVerticalNavPane, setShowVerticalNavPane] = useState(false);
    const [hidden, setHidden] = useState(true);

    const keywordString = useParams()["*"];

    useEffect(() => {
        const fetchData = async() => {
            _photosData = await PhotosData.getInstance();
            _verticalNavPaneItems = getVerticalNavPaneItems();
            setHidden(false);
        }
        fetchData();
      }, []);    
      
    const showFullSize = (currentKeyword: Keyword, photoId: string) => {
        document.body.style.overflow = "hidden";
        const currentIndex = currentKeyword.photoIds.indexOf(photoId);
        setFullSizeVisible(true);
        setCurrentIndex(currentIndex);
        setShowVerticalNavPane(false);
    }

    const hideFullSize = () => {
        document.body.style.overflow = "auto";
        setFullSizeVisible(false);
    }

    const onClickNavToggle = () => {
        setShowVerticalNavPane(!showVerticalNavPane);
    }

    if (hidden || _photosData === undefined || _verticalNavPaneItems.length == 0)
        return <div>Chargement...</div>;

    const currentKeyword = keywordString != null ? getCurrentKeyword(keywordString) : undefined;

    const imageDefs: PhotoViewerImageDef[] | undefined =
        currentKeyword?.photoIds.map(id =>
        ({
            url: PhotosData.FullSizeAzureFolder + _photosData?._photos[id].path,
            width: _photosData?._photos[id].width,
            height: _photosData?._photos[id].height
        }));

    return <div className='photosPage'>
        <a className="navToggle" onClick={onClickNavToggle}>&#9776;</a>
        {showVerticalNavPane ?
            <VerticalNavPane paneItems={_verticalNavPaneItems} hide={() => setShowVerticalNavPane(false) } />
            : null}
        {currentKeyword !== undefined && fullSizeVisible && imageDefs !== undefined ?
            <PhotoViewer
                backgroundColor={PhotoViewerBackground.black}
                onClose={hideFullSize}
                imageDefs={imageDefs}
                initialCurrentIndex={currentIndex} /> : null}
        {currentKeyword === undefined ? null :
            <div>
                <div className="currentCategory">{currentKeyword.name}</div>
                <div> {currentKeyword.photoIds.map(photoId =>
                    <img className="photoThumbnailImg"
                        onClick={() => showFullSize(currentKeyword, photoId)}
                        src={PhotosData.ThumbnailAzureFolder + _photosData?._photos[photoId].thumbPath}
                        title={_photosData?._photos[photoId].path} alt="" />)}
                </div>
            </div>}
    </div>
}
