import * as React from "react";
import { PhotoViewer, PhotoViewerBackground } from "./PhotoViewer";
import { VerticalNavPane, VerticalNavPaneItem } from "./VerticalNavPane";
import { MonnaiesList } from "./MonnaiesList";
import { TabularData } from "./TabularData";
import { MonnaiesData, MonnaiesDataUtil } from "./MonnaiesData";
import { Utils } from "./Utils";
import './index.css';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MonnaiesListUrl: string = "https://millot-blobs-cdn.azureedge.net/photos/monnaies.tsv";

let _monnaies: string[][];
let _verticalNavPaneItems: VerticalNavPaneItem[];

function getMonnaieCount(category?: string, subCategory?: string): number | undefined {
    if (category === undefined)
        return undefined;

    if (category === "Nouveau")
        return _monnaies.filter(row => row[MonnaiesData.Nouveau] === category).length;

    return _monnaies.filter(row => row[MonnaiesData.Type] === category + "\\" + subCategory).length;
}

function getVerticalNavPaneItems(): VerticalNavPaneItem[] {
    const monnaiesTypes = _monnaies.map(row => row[MonnaiesData.Type]);
    const cleanedMonnaiesTypes = monnaiesTypes.filter(t => t !== undefined && t.indexOf("\\") !== -1);
    const dedupedMonnaiesTypes = Utils.dedupArray(cleanedMonnaiesTypes);
    const typesAndSubtypes = dedupedMonnaiesTypes.map(t => t.split('\\'));
    const topLevelTypes = Utils.dedupArray(typesAndSubtypes.map(t => t[0]));

    let items: VerticalNavPaneItem[] = [];

    items.push({ indentLevel: 0, label: "Nouveau", count: getMonnaieCount("Nouveau"), navigateTo: "/Monnaies/Nouveau" });

    topLevelTypes.forEach((topLevel) => {
        items.push({ indentLevel: 0, label: topLevel });
        const filteredtypesAndSubtypes = typesAndSubtypes.filter(tands => tands[0] === topLevel);
        const childItems: VerticalNavPaneItem[] = filteredtypesAndSubtypes.map(tands => {
            const countItems = cleanedMonnaiesTypes.filter(v => v.split('\\')[0] === tands[0] && v.split('\\')[1] === tands[1]).length;
            return {
                indentLevel: 1,
                label: tands[1],
                count: countItems,
                navigateTo: "/Monnaies/" + tands[0] + "/" + tands[1],
            }
        });
        items = items.concat(childItems);
    });

    return items;
}

export function MonnaiesLayout() {
    const [fullSizeUrls, setfullSizeUrls] = useState<string[]>([]);
    const [fullSizeVisible, setFullSizeVisible] = useState(false);
    const [thumbSize, setThumbSize] = useState(128);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showVerticalNavPane, setShowVerticalNavPane] = useState(false);
    const [hidden, setHidden] = useState(true);

    const keywordString = useParams()["*"];

    useEffect(() => {
        const fetchData = async () => {
            _monnaies = await TabularData.fetchFromUrl(MonnaiesListUrl, true);
            MonnaiesDataUtil.setMonnaiesUrls(_monnaies);
            _verticalNavPaneItems = getVerticalNavPaneItems();
            setHidden(false);
        }
        fetchData();
    }, []);

    const showFullSize = (fullSizeUrls: string[], currentIndex: number) => {
        document.body.style.overflow = "hidden";
        setfullSizeUrls(fullSizeUrls);
        setFullSizeVisible(true);
        setCurrentIndex(currentIndex);
        setShowVerticalNavPane(false);
    }

    const hideFullSize = () => {
        document.body.style.overflow = "auto";

        setFullSizeVisible(false);
    }

    const onClickNavToggle = () => {
        setShowVerticalNavPane(!showVerticalNavPane);
    }

    if (hidden)
        return <div>Chargement...</div>;

    let category: string | undefined;
    let subCategory: string | undefined;

    if (keywordString !== "" && keywordString !== null && keywordString !== undefined) {
        const splitIndex = keywordString.indexOf('/');
        if (splitIndex === -1) {
            category = keywordString;
        }
        else {
            category = keywordString.slice(0, splitIndex);
            subCategory = keywordString.slice(splitIndex + 1);
        }
    }
    else
        category = "Nouveau";

        return <div className='monnaiePage'>
        <a className="navToggle" onClick={onClickNavToggle}>&#9776;</a>
        <input className="thumbSlider" type="range" min="50" max="256" defaultValue="128" onChange={ (event) => setThumbSize(Number(event.target.value)) } />
        {showVerticalNavPane ? <VerticalNavPane hide={ () => setShowVerticalNavPane(false) } paneItems={_verticalNavPaneItems} /> : null}
        {fullSizeVisible ?
            <PhotoViewer
                backgroundColor={PhotoViewerBackground.white}
                onClose={hideFullSize}
                imageDefs={fullSizeUrls.map(u => ({ url: u }))}
                initialCurrentIndex={currentIndex} /> : null}
        <div className="monnaiesListAndTitle" >
            <div className="currentCategory">{category === undefined ? "" : category + (subCategory ? " - " + subCategory : "") + " (" + getMonnaieCount(category, subCategory) + ")"}</div>
            <MonnaiesList monnaies={_monnaies} category={category} subcategory={subCategory} showFullSize={showFullSize} thumbSize={thumbSize} />
        </div>
    </div>

}
