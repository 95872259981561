import * as React from "react";
import { MonnaiesLayout } from './MonnaiesLayout';
import { MusicLayout } from './MusicLayout';
import { FilmsLayout } from './FilmsLayout';
import { PleiadeLayout } from './PleiadeLayout';
import { HomeLayout } from './HomeLayout';
import { VideosLayout } from './VideosLayout';
import { PhotosLayout } from './PhotosLayout';
import { GenealogieLayout } from './GenealogieLayout';
import { BrowserRouter, Route, Link, Routes } from "react-router-dom"
import { GenealogiePatronymLayout } from "./GenealogiePatronymLayout";
import { GenealogieIndividuLayout } from "./GenealogieIndividuLayout";

export function App() {
    return <BrowserRouter>
        <div className="topMenu">
            <Link to="/">Home</Link>
            <Link to="/Photos">Photos</Link>
            <Link to="/Videos">Videos</Link>
            <Link to="/Genealogie">G&eacute;n&eacute;alogie</Link>
            <Link to="/Music">Musique</Link>
            <Link to="/Films">Films</Link>
            <Link to="/Pleiade">Pl&eacute;iade</Link>
        </div>
        <Routes>
            <Route path="/" Component={HomeLayout} />
            <Route path="/Photos/*" Component={PhotosLayout} />
            <Route path="/Films" Component={FilmsLayout} />
            <Route path="/Monnaies/*" Component={MonnaiesLayout} />
            <Route path="/Music" Component={MusicLayout} />
            <Route path="/Pleiade" Component={PleiadeLayout} />
            <Route path="/Genealogie/Individu/:id" Component={GenealogieIndividuLayout} />
            <Route path="/Genealogie/Patronyme/:lastname" Component={GenealogiePatronymLayout} />
            <Route path="/Genealogie" Component={GenealogieLayout} />
            <Route path="/Videos/*" Component={VideosLayout} />
        </Routes>
    </BrowserRouter>
}