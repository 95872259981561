import * as React from "react";
import './index.css';
import { GenealogieData, Individual, Event } from "./GenealogieData";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

let _genealogieData: GenealogieData;

function RenderIndividual(individual: Individual): JSX.Element {
    return <span><Link to={"/Genealogie/Individu/" + individual?.id}>{individual.lastName} {individual.firstName}</Link> ({individual.getShortDates()}) <i>{individual.job}</i></span>;
}

function RenderIndividualById(id: string): JSX.Element {
    const individual = _genealogieData._individuals[id];

    if (individual === null || individual === undefined)
        return <span>Individu {id} inconnu</span>;

    return RenderIndividual(individual);
}

function RenderFamily(id: string, familyId: string): JSX.Element {
    const family = _genealogieData._families[familyId];
    const spouseId = family.husbandId === id ? family.wifeId : family.husbandId;
    const renderUnion = <div><b>Union:</b> {RenderIndividualById(spouseId)}</div>;
    const renderEvents = family.events.map(e => <div>{e.type} le {e.date} à {e.place}{<div><i>{e.note}</i></div>}</div>);
    const renderChildren = <div>{family.childrenIds.map(id => <li>{RenderIndividualById(id)}</li>)}</div>;
    return <div>{renderUnion}{renderEvents}<ul>{renderChildren}</ul><p/></div>
}

function getAncestorsWithLevel(id: string, currentLevel: number, ancestors: {id: string, level: number}[]) {
    const individual = _genealogieData._individuals[id];
    if (individual === null || individual === undefined || !individual.familyIdAsChild)
        return;

    const parentFamily = _genealogieData._families[individual.familyIdAsChild];
        if (parentFamily.husbandId != "") {
            ancestors.push({id: parentFamily.husbandId, level: currentLevel + 1});
            getAncestorsWithLevel(parentFamily.husbandId, currentLevel + 1, ancestors);
        }
        if (parentFamily.wifeId != "") {
            ancestors.push({id: parentFamily.wifeId, level: currentLevel + 1});
            getAncestorsWithLevel(parentFamily.wifeId, currentLevel + 1, ancestors);
        }

}

function renderAncestorsOrderedByBirthPlace(id: string): JSX.Element {
    let ancestors: {id: string, level: number}[] = [];
    getAncestorsWithLevel(id, 0, ancestors);

    let ancestorPerBirthPlace: { [birthPlace: string]: {id: string, level: number}[] } = {};

    ancestors.forEach(a => {
        const individual = _genealogieData._individuals[a.id];
        const birth = individual.getBirthEvent();
        if (birth != undefined && birth.place.length > 0) {
            if (birth.place in ancestorPerBirthPlace)
                ancestorPerBirthPlace[birth.place].push({id: a.id, level: a.level});
            else
                ancestorPerBirthPlace[birth.place] = [{id: a.id, level: a.level}];
        }
    });
    const ancestorPerBirthPlaceArray = Object.entries(ancestorPerBirthPlace).sort(
        (a, b) => a[0].localeCompare(b[0]) );

    const lieuxRender: JSX.Element = 
        <div> {
            ancestorPerBirthPlaceArray.map(a => 
                <div><b>{a[0]}</b>{a[1].sort((a, b) => a.level - b.level).map(i => <li>{i.level} {RenderIndividualById(i.id)}</li>)}<p/></div>
            )
        }
        </div>

    return <div><h2>Ancètres par lieu</h2>{lieuxRender}</div>;
}

function renderEvent(e: Event): JSX.Element {
    return <div>
        <div><b>{e.type}</b>{e.isEmpty() ? "" : ":"} {e.date.length > 0 ? "le " + e.date : ""} {e.place.length > 0 ? <span>à {e.place}</span> : ""}</div>
        {e.note.length != 0 ? <div><i>{e.note}</i></div> : ""}
    <p/>
    </div>
}

export function GenealogieIndividuLayout() {
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            _genealogieData = await GenealogieData.getInstance();
            setHidden(false);
        }
        fetchData();
    }, []);

    const id = useParams()["id"] as string;

    if (hidden || _genealogieData === undefined)
        return <span>Chargement des données...</span>;

    const individual = _genealogieData._individuals[id];
    const renderindividual = 
    <div>
        <h1>{individual.lastName} {individual.firstName}</h1>
        <div>{individual.job}</div><p/>
        {individual.events.map(e => renderEvent(e))}
    </div>;

    let renderParents: JSX.Element = <div/>;
    if (individual.familyIdAsChild) {
        const parentFamily = _genealogieData._families[individual.familyIdAsChild];
        renderParents = <div><div><b>Père:</b> {RenderIndividualById(parentFamily.husbandId)}</div><p/>
            <div><b>Mère:</b> {RenderIndividualById(parentFamily.wifeId)}</div><p/></div>;
    }

    const renderFamilies: JSX.Element[] = individual.familyIdsAsSpouse.map(familyId => <div>{RenderFamily(id, familyId)}</div>);

    return <div className='genealogieIndividualPage'>{renderindividual}{renderParents}{renderFamilies}{renderAncestorsOrderedByBirthPlace(id)}</div>
};

