import * as React from "react";
import './index.css';
import { GenealogieData, Individual } from "./GenealogieData";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

let _genealogieData: GenealogieData;

export function GenealogiePatronymLayout() {
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            _genealogieData = await GenealogieData.getInstance();
            setHidden(false);
        }
        fetchData();
    }, []);

    const lastName = useParams()["lastname"] as string;

    if (hidden || _genealogieData === undefined)
        return <span>Chargement des données...</span>;

    const patronyms = _genealogieData._patronyms[lastName];
    if (!patronyms)
        return <span>Patronyme {lastName} inconnu</span>;

    const individualsForPatronym = patronyms.individualIds.
            map(id => _genealogieData?._individuals[id]).
            sort((a, b) => (a as Individual).firstName.localeCompare((b as Individual).firstName));

    const individualButtons: JSX.Element[] = individualsForPatronym.map(i =>
        <div><Link to={"/Genealogie/Individu/" + i?.id}>{i.firstName} </Link>{i.getShortDates()}</div>);

    return <div className='genealogiePatronymPage'><h1>{lastName}</h1><p /><div>{individualButtons}</div></div>;
}
